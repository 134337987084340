import FrenchPage from '../components/lifecycle/FrenchPage';
import Booking from './Booking'

export default function BookingFR() {
  return (
    <>
      <FrenchPage />
      <Booking />
    </>
  );
}